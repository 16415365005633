import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
import { Chart, CanvsJS } from "node_modules/chart.js";
declare var $;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  
  totalUser : any;
  totalKyc : any;
  totalTransaction : any;
  totalQR : any;
  KycDataYear :any = [1, 1, 9, 46, 10, 14, 1, 28, 5, 1, 8, 1];
  earningDataYear : any = [18, 10, 22, 11, 26, 23, 15, 6, 5, 3, 8, 16];

  constructor(private service:CommonService) { }

  ngOnInit(): void {
    this.chart1();
    this.chart2();
    this.dashboard()
  }

  dashboard(){
    let url="admin/adminDashboard"
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.statusCode == 200){
        this.totalUser = res.result.TotalUser
        this.totalKyc = res.result.TotalKyc
        this.totalQR = res.result.TotalQr
        this.totalTransaction = res.result.TotalTransaction
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    },
    (err)=>{
      this.service.hideSpinner()
      this.service.errorToast(err.responseMessage)
    })
  }


  /* -----------------------Chart UI --------------------------- */
  chart1() {
    var myChart = new Chart("myChart", {
      type: "bar",
      showInLegend: false,
      toolTipContent: "{legendText}",
      data: {
        labels: [
          "Jan-2021",
          "Feb-2021",
          "Mar-2021",
          "Apr-2021",
          "May-2021",
          "Jun-2021",
          "Jul-2021",
          "Aug-2021",
          "Sep-2021",
          "Oct-2021",
          "Nov-2021",
          "Dec-2021",
          
        ],
        datasets: [
          {
            label: "Kyc",
            data: this.KycDataYear,
            
            backgroundColor: [
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
            ],
            borderColor: [
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
              "#036ef4",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },

        scales: {
          yAxes: [
            {
              scaleLabel: {
                labelString: "Unit",
                display: true,
              },
              valueFormatString: " ",
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                labelString: "Month",
                display: true,
              },
              valueFormatString: " ",
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }
  chart2() {
    var myChart1 = new Chart("myChart2", {
      type: "line",
      showInLegend: false,
      toolTipContent: "{legendText}",
      data: {
        labels: [
          "Jan-2021",
          "Feb-2021",
          "Mar-2021",
          "Apr-2021",
          "May-2021",
          "June-2021",
          "July-2021",
          "Aug-2021",
          "Sep-2021",
          "Oct-2021",
          "Nov-2021",
          "Dec-2021",
         
        ],
        datasets: [
          {
            label: "Earning",
            data: this.earningDataYear,
            backgroundColor: ["transparent"],
            borderColor: ["rgb(9 49 236 / 88%)"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },

        scales: {
          yAxes: [
            {
              scaleLabel: {
                labelString: "Earning in Percentage (%)",
                display: true,
              },
              valueFormatString: " ",
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                labelString: "Month",
                display: true,
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }
}
