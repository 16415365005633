<!-- forgot password form start-->
<div class="login-wrapper">
  <div class="container-common">
    <div class="row justify-content-center">
      <div class="col-md-6">
          <!--Left container image-->
        <div>
           <img src="/assets/Product image/security.png" alt=" " class="rounded float-left" id="left-container-image">
        </div>
        <!-- form -->
        <div class="d-flex align-items-center minheight70vh">
          <form class="login_box_outer w-100" [formGroup]="form">
            <div class="login-box max-WT-520">
              <div class="login-right-block">
                <div class="login-heading" style="text-align: center;">
                  <img style="font-size: 8px; max-height: 100px;width: 50%;" src="assets/img/admin-logo.png" alt="ICON" class="lg-logo">
                </div>
                <div class="login-heading">
                  <h4 style="color: black;">FORGOT PASSWORD</h4>
                </div>
                <div class="login-box-body">
                  <p class="common-paragrph text-center">Enter your registered email address. We will help you to reset your password.</p>
                  <div class="form-group">
                    <input type="email" class="form-control" name="email" formControlName="email" placeholder="Enter your email address" maxlength="60" (keypress)="service.preventSpace($event)" />
                    <div *ngIf="form.get('email').errors && (form.get('email').touched || form.get('email').dirty)" class="text-danger">
                      <span *ngIf="form.get('email').hasError('pattern')"> *Please enter valid email address.</span>
                      <span *ngIf="form.get('email').hasError('required')"> *Email address is required.</span>
                    </div>
                  </div>
                </div>
                <div class="text-center mt40 admin-profile">
                  <button type="button" class="btn btn-login btn-large  width100 font-100"  aria-labelledby="exampleModal" [disabled]="!form.valid" (click)="verfyEmail()">SUBMIT</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- forgot password form end-->
  
<!-- otp modal -->
<div class="modal" id="exampleModal" tabindex="1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [ngClass]="showModal ? 'showModal':''">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"  id="exampleModalLabel" style="color: black;">Please Enter OTP</h5>
        <button type="button" (click)="hideModal()" class="close"  data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" style="color: black;">&times;</span>
        </button>
      </div>
      <div class="modal-body mb40 mt40" style="display: flex; justify-content: center; white-space: nowrap;">
        <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:6,allowNumbersOnly:true}"  style="background:transparent ; color: #fff;" ></ng-otp-input>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideModal()" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="veryfyOtp()">Verify</button>
      </div>
    </div>
  </div>
</div>
  