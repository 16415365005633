import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;
  loginSub = new BehaviorSubject(``);
  userId= new Subject<any>();
  viewKyc=new Subject<any>()


  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: true,
  };
  
  // baseURL = 'https://java-whitepaper.mobiloitte.org/';
  // baseURL="http://182.72.203.247:4090/"
  baseURL="https://node-mvp.mobiloitte.com/api/v1/";

  // baseURL="http://172.16.1.122:3005/api/v1/"    // local
  // baseURL="http://182.72.203.245:1868/api/v1/"

  constructor(private routes:Router,private spinner:NgxSpinnerService,private toastr:ToastrService, private http:HttpClient) { }

  // ---------------- get Api function -------------------- //
getApi(endPointURL, isHeader): Observable<any> {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token':  localStorage.getItem('token')
      })
    }
  }
  return this.http.get(this.baseURL + endPointURL, httpHeaders)
}


// ---------------- post Api Function ------------------- //
postApi(endPointURL, data, isHeader): Observable<any> {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': ` ${localStorage.getItem('token')}`
      })
    }
  }
    return this.http.post(this.baseURL + endPointURL, data, httpHeaders)
  }

// ------------------ put Api Function ----------------- //
putApi(endPointURL, data, isHeader): Observable<any> {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token':localStorage.getItem('token')
      })
    }
  }
  return this.http.put(this.baseURL + endPointURL, data, httpHeaders)
}
  
// ------------------ delete Api Function -------------- //
deleteApi(endPointURL, bodyData, isHeader): Observable<any> {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }),
      body: bodyData
    }
  }
  return this.http.delete(this.baseURL + endPointURL, httpHeaders)
}

  // spinner
  showSpinner(){
    this.spinner.show()
  }
  hideSpinner(){
    this.spinner.hide()
  }

  // Header Managment
  changeLoginSub(msg) {
    this.loginSub.next(msg);
  }
    

  /** to prevent first space */
  preventSpace(event) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  // logout
  onLogout() {
    localStorage.removeItem('token');
    this.changeLoginSub('logout');
    this.routes.navigate(['/login']);
  }

  // toastr
  successToast(msg:any){
    this.toastr.success(msg)
  }
  errorToast(msg:any){
    this.toastr.error(msg)
  }

  public today(){
    return new Date().toISOString().split('T')[0]
  }

  public onFirstComponentButtonClick() {
    this.invokeFirstComponentFunction.emit();
  }

  uploadFile(endPointURL, data): Observable<any> {
  //  let httpHeaders = {
  //     headers: new HttpHeaders({
  //       // 'Content-Type': 'application/json',
  //       'token':  localStorage.getItem('token')
  //     })
  //   }
    return this.http.post<any>((this.baseURL + endPointURL), data)
  }

  getPosition(): Promise<any>{
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
      resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
      },
      err => {
        reject(err);
      });
    });
  }
  public getIPAddress(){  
    return this.http.get("https://jsonip.com/?format=json");  
  } 
    
  public isLoggedIn() {
    return localStorage.getItem("token") !== null;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
      // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
