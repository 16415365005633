import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-user-kyc',
  templateUrl: './view-user-kyc.component.html',
  styleUrls: ['./view-user-kyc.component.scss']
})
export class ViewUserKycComponent implements OnInit {
  userId: any;
  viewKycList: any;
  docSide : any
  img : any
  kycDetails: any;
  viewKycDetails:any
  constructor(private activatedRoute:ActivatedRoute,private router :Router, private commonService:CommonService) { }

  kycData : any = [
    {
      kycUserId: 11873,
      _id : 12,
    name : 'Tanveer',
    idNumber : "KLPH0052L",
    IdType : "PAN",
    kycStatus : 'PENDING',
    createdAt : '12/07/2021, 8:05 PM',
    status : 'PENDING',
    docFrontPage :"assets/img/adhar-front.png",
    docBackPage :"assets/img/adhar-back.png",
  }]

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res:any)=>{
      this.userId = res.id
      })
      this.viewKyc()
  }
  
  viewKyc(){
    let apiReqData={
      _id:this.userId
    }
    let url="admin/viewKyc"
    this.commonService.showSpinner()
    this.commonService.postApi(url,apiReqData,1).subscribe((res)=>{
      if(res.statusCode==200)
      {
        this.kycDetails=res.result
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
      }
     else{
       this.commonService.hideSpinner()
       this.commonService.errorToast(res.responseMessage)
     }
    },
    (err)=>{
      this.commonService.hideSpinner()
      this.commonService.errorToast("data not found")
    })
  }
  
  kycVerificationAcception()
  {
    let url="admin/kycVerificationAcception"
    let apiReqData={
      _id:this.userId
    }
    this.commonService.showSpinner()
    this.commonService.putApi(url,apiReqData,1).subscribe((res)=>{
      if(res.statusCode==200)
      {
        console.log("Api req data-->",res.result)
        this.viewKycDetails=res.result
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
      }
      else{
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
      },
      (err)=>{
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      })
  }

  kycVerificationRejection()
  {
    let url="admin/kycVerificationRejection"
    let apiReqData={
      "_id":this.userId
    }
    this.commonService.showSpinner()
    this.commonService.putApi(url,apiReqData,1).subscribe((res)=>{
      if(res.statusCode==200)
      {
        console.log("Api req data-->",res.result)
        this.viewKycDetails=res.result
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
      }
      else{
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
      },
      (err)=>{
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      })

  }
  backKyc(){
   this.router.navigate(['/list-user'])
   localStorage.setItem('viewUserTab','KYC')
  }
  showDocument(side,img){
    this.docSide = side
    this.img = img
  }
  
}
