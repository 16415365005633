import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  view: boolean=false;

  //to change icon type
  confirmPassword:any="password"
  newPasswordType: any="password"

  constructor(public service:CommonService, private router:Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    // form controls and validations
    this.form = new FormGroup({
      oldPassword: new FormControl('', Validators.compose([Validators.required])),
      newPassword: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(?=^.{8,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)])),
      confirmPassword: new FormControl('', Validators.compose([Validators.required])),
    })
  }

  back() {
    this.router.navigate(['/my-profile'])
  }

  // change icon type onclick eye-icon
  viewConfirm(val){
    if(val){
      this.confirmPassword="text"
    }
    else{
      this.confirmPassword="password"
    }
  }
  viewNew(val){
    if(val){
      this.newPasswordType="text"
    }
    else{
      this.newPasswordType="password"
    }
  }

  // hit api on click change password
  changePassword() {
    var data = {
      'password': this.form.value.oldPassword,
      'newPassword': this.form.value.newPassword
    }
    console.log(data);
    let url="admin/changePassword"
    this.service.showSpinner();
    this.service.putApi(url, data,1).subscribe(res => {
      this.service.hideSpinner();
      if (res.statusCode == 200) {
        this.service.successToast('Password updated successfully.');
        this.service.onLogout()
        this.router.navigate(['/login'])
      } else {
        this.service.errorToast(res.responseMessage);
      }
    }, err => {
      this.service.hideSpinner();
      if (err.statusCode == '401') {
        this.service.errorToast('Unauthorized Access.');
      } else {
        this.service.errorToast('Old password not mathced.');
      }
    })
  }
  
  // change icon on click eye-icon
  viewPassword(type) {
    if (type == 'lock') {
      this.view = true
    } else {
      this.view = false
    }
  }
}
