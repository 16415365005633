import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  currentTab: any = 'PERSONAL_INFORMATION'
  
  constructor() { }

  ngOnInit(): void {
  }
  selectTab(tab){
    this.currentTab = tab
  }
}
