import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

//component
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListKycComponent } from './pages/kyc-management/list-kyc/list-kyc.component';
import { ListTransactionComponent } from './pages/transaction-management/list-transaction/list-transaction.component';
import { ViewTransactionDetailsComponent } from './pages/transaction-management/view-transaction-details/view-transaction-details.component';
import { ChangePasswordComponent } from './pages/auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ProfileComponent } from './pages/auth/profile/profile.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { ListUserComponent } from './pages/user-management/list-user/list-user.component';
import { ViewComponent } from './pages/user-management/view/view.component';
import { ViewKycComponent } from './pages/kyc-management/view-kyc/view-kyc.component';
import { QRCodeManagementComponent } from './pages/qr-code-management/qr-code-management.component';
import { MyProfileComponent } from './pages/dashboard/my-profile/my-profile.component';

const routes: Routes = [
  {path:"",redirectTo:"login",pathMatch:"full"},
  // auth
  {path:"login",component:LoginComponent},
  {path:"profile",component:ProfileComponent,canActivate : [AuthGuard]},
  {path:"forgot-password",component:ForgotPasswordComponent},
  {path:"reset-password",component:ResetPasswordComponent},

  // user managemnt
  {path:"list-user",component:ListUserComponent,canActivate : [AuthGuard]},
  {path:"view-user",component:ViewComponent,canActivate : [AuthGuard]},
  {path:'dashboard',component : DashboardComponent,canActivate : [AuthGuard]},
  {path:'change-password', component:ChangePasswordComponent,canActivate : [AuthGuard]},
  {path:'my-profile',component:MyProfileComponent,canActivate:[AuthGuard]},

  //transaction-management
  {path:'transaction-management',component:ListTransactionComponent,canActivate : [AuthGuard]},
  {path:'view-transaction',component:ViewTransactionDetailsComponent,canActivate : [AuthGuard]},
  {path:"view-user",component:ViewComponent,canActivate : [AuthGuard]},

  //KYC management
  {path:'list-kyc',component:ListKycComponent,canActivate : [AuthGuard]},
  {path:"view-kyc",component:ViewKycComponent,canActivate : [AuthGuard]},

  //QR code management
  {path:'qr-code',component:QRCodeManagementComponent,canActivate : [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
