import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPassword: FormGroup;
  email: any = atob(localStorage.getItem("email"));
   //to change icon type
   view: boolean=false;
   confirmPassword:any="password"
   newPasswordType: any="password"

  constructor(public service: CommonService, public router: Router) { }

  ngOnInit(): void {
    this.resetPassword = new FormGroup({
      "password": new FormControl('', ([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)])),
      "confirmPassword": new FormControl('', [Validators.required])
    });
  }

   // change icon type onclick eye-icon
   viewConfirm(val){
    if(val){
      this.confirmPassword="text"
    }
    else{
      this.confirmPassword="password"
    }
  }
  viewNew(val){
    if(val){
      this.newPasswordType="text"
    }
    else{
      this.newPasswordType="password"
    }
  }

    // change icon on click eye-icon
  viewPassword(type) {
    if (type == 'lock') {
      this.view = true
    } else {
      this.view = false
    }
  }

  resetApi() {
    let url = "admin/resetPassword"
    const data = {
      "email": this.email,
      "password": this.resetPassword.value.password,
      "token": "string"
    }
    this.service.showSpinner()
    this.service.postApi(url,data,0).subscribe((res)=>{
      if(res.statusCode == 200){
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
        localStorage.clear()
        this.router.navigate(['/login'])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    },(err)=>{
      this.service.hideSpinner()
        this.service.errorToast("Something went wrong.")
    })
  }

}
