import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  currentTab: any = 'GENERAL'
  constructor() { }

  ngOnInit(): void {
    this. viewUserDetails()
  }
  selectTab(tab){
    this.currentTab = tab
  }
 viewUserDetails()
 {
   let data={

   };
   console.log("view user details->,data");
   
 }

}
