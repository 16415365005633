<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">USER MANAGEMENT</h1>
        <hr>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div>
                        <div>
                            <div class="tab-pane1">
                                <div class="sec_head_new sec_head_new_after">

                                    <form [formGroup]="searchForm">
                                        <div class="row align-items-center">
                                            <div class="col-md-3 pr-0">
                                                <div class="filter_search mb20 width100">
                                                    <label class="d-flex align-items-center">Search:</label>
                                                    <div class="input-group filter_search_group">
                                                        <input type="text" class="form-control overflow-text" formControlName="search" placeholder="Search by email" maxlength="60">
                                                        <div class="input-group-append">
                                                            <button class="btn btn_search_group"><img src="assets/img/icon-search.png" (click)="search()"  alt="Search"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 pr-0">
                                                <label class="d-flex align-items-center">From Date:</label>
                                                <input type="date" class="form-control mb20" formControlName="fromDate" [max]="commonService.today()"> 
                                            </div>
                                            <div class="col-md-2 pr-0">
                                                <label class="d-flex align-items-center">To Date:</label>
                                                <input type="date" formControlName="toDate" [readonly] = "!this.searchForm.controls['fromDate'].value" [min]="this.searchForm.controls['fromDate'].value" class="form-control mb20">
                                            </div>
                                            <div class="col-md-5">
                                                <div class="text-center admin-btn mb2 mt10">
                                                    <button type="button" class="btn btn-theme" (click)="search()">Search</button>
                                                    <button type="button" class="btn btn-theme" (click)="reset()">Reset</button>
                                                    <button type="button" class="btn btn-theme btn-export" (click)="exportAsXLSX()">EXPORT EXCEL</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="table-responsive" style="margin-bottom: 60px;">
                                <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap" aria-describedby="user management list">
                                    <thead class="thead">
                                        <tr class="no_wrap_th text-center">
                                            <th scope="col" class="Sorting-img heading1">S.No</th>
                                            <th scope="col" class="Sorting-img">Name</th>
                                            <th scope="col" class="Sorting-img">Email Address</th>
                                            <th scope="col" class="Sorting-img">Contact Number</th>
                                            <th scope="col" class="Sorting-img">Created At</th>
                                            <th scope="col" class="Sorting-img">Status</th>
                                            <th scope="col" class="action_td_btn3 Sorting-img heading2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of customerData | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index" class="text-center">
                                            <td> {{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                            <td class="content">{{item?.firstName +' '+ item.lastName|| '--'}}</td>
                                            <td class="content">{{item?.email || '--'}}</td>
                                            <td class="content">{{item?.mobileNumber ||'--'}}</td>
                                            <td class="content">{{item?.updatedAt | date : 'dd/MM/yyyy, hh:mm a'}} </td>
                                            <td class="content">{{item?.status || '--'}}</td>
                                            <td class="justify-content-center d-flex">
                                                <button class="btn btn-info ml-2 bt-margin bth" (click)="viewUser(item?._id)" title="View">
                                                    <em class="fa fa-eye" aria-hidden="true"></em>
                                                </button>
                                                <button *ngIf="item.status == 'ACTIVE'" class="btn btn-success ml-2 bth" title="Block" data-toggle="modal" data-target="#BlockModal" (click)="getUserId(item?._id,item?.status)">
                                                    <em class="fa fa-check" aria-hidden="true"></em>
                                                </button>
                                                <button *ngIf="item.status == 'BLOCK'" class="btn btn-danger ml-2 bth" title="Block" data-target="#BlockModal" data-toggle="modal" (click)="getUserId(item._id,item.status)">
                                                    <em class="fa fa-times" aria-hidden="true"></em>
                                                </button>
                                                <button class="btn btn-danger ml-2 bth" title="Delete" data-toggle="modal" data-target="#deleteModal"  (click)="getUserId(item?._id,item?.status)" >
                                                    <em class="fa fa-trash" aria-hidden="true"></em>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr *ngIf="customerData == 0">
                                            <td class="table-no-record" colspan="7">No Record Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > itemsPerPage">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<!-- delete user-management modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
<div class="modal-dialog max-WT-500">
    <form class="change_password">
        <div class="modal-content">
            <div class="modal-header d-block text-center modal-header-custm">
                <h5 class="modal-title d-inline-block">Delete</h5>
            </div>
            <div class="modal-inner-content">
                <div class="modal-body">
                    <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                        <div class="w-100">
                            <p>Are you sure you want to delete this user?</p>
                            <div class="modal-btn-box">
                                <button type="submit" class="btn btn-primary" data-dismiss="modal" (click)="deleteUser()">Yes</button>
                                <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</div>
<div class="modal fade global-modal reset-modal" id="BlockModal">
<div class="modal-dialog max-WT-500">
    <form class="change_password">
        <div class="modal-content">
            <div class="modal-header d-block text-center modal-header-custm">
                <h5 class="modal-title d-inline-block">{{statusString}}</h5>
            </div>
            <div class="modal-inner-content">
                <div class="modal-body">
                    <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                        <div class="w-100">
                            <p>Are you sure you want to {{statusString | lowercase}} this user?</p>
                            <div class="modal-btn-box">
                                <button type="submit" class="btn btn-primary" data-dismiss="modal" (click)="blockUnblockUser()">Yes</button>
                                <button type="button" class="btn btn-secondary  ml-2" data-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</div>
<div class="container">
<!-- Trigger the modal with a button -->
<!-- Modal -->
<div class="modal fade" id="myModal-export" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header" style="background: goldenrod;">
                <h5 class="modal-title">Confirmation</h5>
            </div>
            <div class="modal-body">
                <p>The EXCEL export file will be generated for download.</p>
                <p>Disable any popup blockers in your browser to ensure proper download.</p>
                <p>Ok to proceed?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success">OK</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
</div>

