import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


// modules
import { MyDatePickerModule } from 'mydatepicker'; 
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClientModule } from '@angular/common/http';
import { NgOtpInputModule } from  'ng-otp-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SplitPipe } from './split.pipe';
import { DatePipe } from '@angular/common';

//components 
import { AppComponent } from './app.component';
import { FooterComponent } from './pages/sharing/footer/footer.component';
import { HeaderComponent } from './pages/sharing/header/header.component';
import { ProfileComponent } from './pages/auth/profile/profile.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { ListUserComponent } from './pages/user-management/list-user/list-user.component';
import { ViewComponent } from './pages/user-management/view/view.component';
import { ViewUserGeneralComponent } from './pages/user-management/view/view-user-general/view-user-general.component';
import { ViewUserKycComponent } from './pages/user-management/view/view-user-kyc/view-user-kyc.component';
import { ViewUserTransactionComponent } from './pages/user-management/view/view-user-transaction/view-user-transaction.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SidebarComponent } from './pages/sidebar/sidebar.component';
import { ChangePasswordComponent } from './pages/auth/change-password/change-password.component';
import { ListTransactionComponent } from './pages/transaction-management/list-transaction/list-transaction.component';
import { ViewTransactionDetailsComponent } from './pages/transaction-management/view-transaction-details/view-transaction-details.component';
import { ListKycComponent } from './pages/kyc-management/list-kyc/list-kyc.component';
import { ViewKycComponent } from './pages/kyc-management/view-kyc/view-kyc.component';
import { QRCodeManagementComponent } from './pages/qr-code-management/qr-code-management.component';
import { MyProfileComponent } from './pages/dashboard/my-profile/my-profile.component';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    ProfileComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ListUserComponent,
    SplitPipe,
    ViewComponent,
    ViewUserGeneralComponent,
    ViewUserKycComponent,
    ViewUserTransactionComponent,
    DashboardComponent,
    SidebarComponent,
    ChangePasswordComponent,
    ListTransactionComponent,
    ViewTransactionDetailsComponent,
    ListKycComponent,
    ViewKycComponent,
    QRCodeManagementComponent,
    MyProfileComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    MyDatePickerModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
