import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "src/app/provider/common.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  userId:any
  view="hide"
  password="password"

  constructor(private router: Router, public commonService: CommonService) {}

  ngOnInit(): void {
    if (this.commonService.isLoggedIn()) {
      this.router.navigate(["/dashboard"]);
    }
    this.loginFormValidation();
  }

  //-------  login form validation -------- //
  loginFormValidation() {
    var data;
    if (localStorage.getItem("loginData")) {
      data = JSON.parse(localStorage.getItem("loginData"));
    }
    this.loginForm = new FormGroup({
      email: new FormControl(data?.email || "", [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,7}|[0-9]{1,3})(\]?)$/i
        ),
      ]),
      password: new FormControl(
        atob(data?.password || ""),
        Validators.required
      ),
      rememberMe: new FormControl(data?.rememberMe || ""),
    });
  }

  // ---------  login form submit ------------- //
  onLogin() {
    let apiReqData = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    console.log("login api req data->", apiReqData);
    this.commonService.showSpinner();
    this.commonService.postApi("admin/login", apiReqData, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          localStorage.setItem("token", res.result.token);
          this.commonService.successToast(res.responseMessage);
          this.userId=res.result._id
          this.commonService.userId.next(this.userId)
          this.router.navigate(["dashboard"]);
          // remenber me
          if (this.loginForm.value.rememberMe) {
            var loginData = {
              email: this.loginForm.value.email,
              password: btoa(this.loginForm.value.password),
              rememberMe: this.loginForm.value.rememberMe,
            };
            localStorage.setItem("loginData", JSON.stringify(loginData));
          } else {
            localStorage.removeItem("loginData");
          }
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },(err: any) => {
        this.commonService.hideSpinner();
        this.commonService.errorToast("Invalid credential.");
      }
    );
  }

  hidePassword()
  {
    if(this.password=="password")
    {
      this.password="text"
      this.view="show"
    }
    else{
      this.view="hide"
      this.password="password"
    }
  }

}
