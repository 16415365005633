<main class="middle-content" >
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">VIEW USER</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">

                    <div class="custom_tabs common-tabs">
                        <div class="row mb20 justify-content-center">
                            <div class="col-sm-12">
                                <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                                    <li class="nav-item flex-fill"
                                        [ngClass]="this.currentTab=='GENERAL'?  'active' : ''">
                                        <a class="nav-link active show " [ngClass]="{'active': currentTab=='GENERAL'}"
                                            (click)="selectTab('GENERAL')" href="javascript:;">GENERAL</a>
                                    </li>
                                    <li class="nav-item flex-fill"> <a class="nav-link active show "
                                            [ngClass]="{'active': currentTab=='transcation'}"
                                            (click)="selectTab('transcation')" href="javascript:;">TRANSACTION</a>
                                    </li>
                                    <li class="nav-item flex-fill"> <a class="nav-link active show "
                                            [ngClass]="{'active': currentTab=='KYC'}" (click)="selectTab('KYC')"
                                            href="javascript:;">KYC</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <app-view-user-general *ngIf="currentTab == 'GENERAL'"></app-view-user-general>
                    <app-view-user-transaction *ngIf="currentTab == 'transcation'"></app-view-user-transaction>
                    <app-view-user-kyc  *ngIf="currentTab == 'KYC' "></app-view-user-kyc>
                </div>
            </div>
        </div>
    </div>
</main>