import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-user-general',
  templateUrl: './view-user-general.component.html',
  styleUrls: ['./view-user-general.component.scss']
})
export class ViewUserGeneralComponent implements OnInit {
  generalDetail : any = [{
    name : "Tanveer",
    email : "tanveer@mailinator.com",
    mobileNumber : 8700112873,
    gender : "Male",
    country : 'India',
    state : 'Delhi',
    city : 'New Delhi',
    zipcode : 110020
  }]
  userId: any;
  userData: any;

  constructor(private service : CommonService,private activatedRoute : ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.userId = res.id
    })
  }

  ngOnInit(): void {
    this.getUserData()
    // console.log(this.userId);
  }

  getUserData(){
    let url ="admin/viewUser"
    let data={_id:this.userId}
    this.service.showSpinner()
    this.service.postApi(url,data,1).subscribe((res)=>{
      if (res.statusCode == 200) {
        this.userData = res.result
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      } else {
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    }),
    (err:any)=>{
      this.service.errorToast(err.message)
    }
  }
}
