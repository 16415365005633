import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $:any
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  showSidebar: boolean
  currentUrl: any;
  profileImage: any;
  profileData: any=[];
  showLogo: boolean = false;
  showModal : boolean = false
  role: any;
  viewprofileData: any=[];

  constructor(public router: Router,public service:CommonService) { 

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = '/'+ e.url.split('/')[1];
        console.log(this.currentUrl);
        if (this.currentUrl === '/' || this.currentUrl === '/login' || this.currentUrl == '/forgot-password') {
          if (localStorage.getItem('token')) { this.router.navigate(['dashboard']) }
        }
      }
    });
   
  }

  showadmin(event) {
    console.log('event', event);
    if (this.showLogo == false) {
      if ((document.getElementById("admin").style.width = "45px")) {
        document.getElementById("admin").style.display = "none";
        this.showLogo = true;
      }
    }
  }


  ngOnInit(): void {
    $('.btn-toggle,.close_panel').click(() => {
      $('body').toggleClass('toggle-wrapper');
    });
    // this.getProfile()
    if (this.service.subsVar==undefined) {
      this.service.subsVar = this.service.
      invokeFirstComponentFunction.subscribe((res) => {
        // this.getProfile();
      });
    }
  }

  // get profile
  // getProfile() {
  //   let url = "admin/viewProfile"
  //   this.service.showSpinner()
  //   this.service.getApi(url, 1).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       this.profileData = res.data
  //       console.log(this.profileData);
  //       this.service.hideSpinner();
  //       this.service.successToast(res.message);
  //     } else {
  //       this.service.hideSpinner();
  //       this.service.errorToast(res.message)
  //     }
  //   }) 
  // } 

  adminProfile() {
    this.router.navigate(['/my-profile'])
  }

  // logout
  logoutModalOpen() {
    console.log('Hell0');
    $('#logoutModal').modal('show')
  }

  onLogout() {
    // $('#logoutModal').modal('hide');
    this.showModal = true
    this.service.onLogout();
  }
}
