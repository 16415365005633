<div class="login-wrapper">
  <div class="container-common">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <!-- Left container image -->
        <div>
          <img src="/assets/Product image/security.png" alt=" " class="rounded float-left" id="left-container-image">
          <div class="d-flex align-items-center minheight70vh mylogin">
            <!-- login form -->
            <form class="login_box_outer w-100" [formGroup]="loginForm">
              <div class="login-box max-WT-520">
                <div class="login-right-block">
                  <div class="login-heading" style="text-align: center;">
                    <img style="font-size: 8px; max-height: 100px; width: 50%" src="assets/img/admin-logo.png" alt="ICON" class="lg-logo"/>
                  </div>
                  <div class="login-heading">
                    <h4 style="color: black">Login</h4>
                  </div>
                  <div class="login-box-body ">
                    <div class="form-group" style="margin-bottom: 6%">
                      <input type="text" formControlName="email" class="form-control" maxlength="50" placeholder="Email address" (keypress)="commonService.preventSpace($event)"/>
                      <div class="text-danger" *ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched)" style="color: red; text-align: start">
                        <span *ngIf="loginForm.get('email').hasError('required')">*Email address is required.</span>
                        <span *ngIf="loginForm.get('email').hasError('pattern')">*Please enter valid email address.</span>
                      </div>
                    </div>
                    <div class="form-group eyeicon">
                      <input [type]="password" maxlength="16" formControlName="password" class="form-control" placeholder="Password" (keypress)="commonService.preventSpace($event)"/>
                      <i class="fa fa-eye" *ngIf="view=='show'" (click)="hidePassword()" ></i>
                      <i class="fa fa-eye-slash" *ngIf="view=='hide'"  (click)="hidePassword()"></i>
                      <div class="text-danger" *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)" style="color: red; text-align: start">
                        <span *ngIf="loginForm.get('password').hasError('required')">*Password is required.</span>
                      </div>
                    </div>
                    <div class="form-group row" style="padding-top: 3%; justify-content: space-between;">
                      <div class="col-6">
                        <div class="remember-text">
                          <label class="checkbox-design" style="color: #404393;">
                            <input type="checkbox" formControlName="rememberMe"/><span></span>Remember me</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="forgot-links">
                          <a [routerLink]="['/forgot-password']" routerLinkActive="router-link-active" style="color: #404393">Forgot Password?</a>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="text-center mt40">
                  <button class="btn btn-login btn-large width100 font-100" [disabled]="!loginForm.valid" (click)="onLogin()">LOGIN</button>
                </div>
              </div>
            </div>
          </form>
        </div>
    </div>       
  </div>
</div>
</div>
</div>