import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { CountryJsonService } from 'src/app/provider/country-json.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  editProfileForm: FormGroup;
  stateArr : any = [];
  id : any;
  userDetail: any;
  editImage: any;
  imgSrc: string;

  constructor(public country : CountryJsonService,public service:CommonService, private router:Router) { }

  ngOnInit(): void {
    this.editProfileFormValidation();
    this.getProfile()
  }

  editProfileFormValidation() {
    this.editProfileForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'phoneNo': new FormControl('', [Validators.required, Validators.pattern(/^[^0][0-9]*$/),  Validators.maxLength(15)]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'image': new FormControl(''),
      'country' : new FormControl('',Validators.required),
      'state' : new FormControl('',Validators.required),
      'city' :  new FormControl('',Validators.required),
      'address': new FormControl('',[Validators.required])
    })
  }

  getProfile() {
    var url = 'admin/viewProfile';
    this.service.showSpinner();
    this.service.getApi(url,1).subscribe(res => {
      // this.service.hideSpinner();
      if (res.statusCode == 200) {
        this.userDetail = res.result;
        this.editImage = this.userDetail.profilePic;
        this.editProfileForm .patchValue({
          'firstName': this.userDetail.firstName || "Satyam",
          'lastName':this.userDetail.lastName || "Dwivedi",
          'email': this.userDetail.email || "satyam@mailinator.com",
          'phoneNo': this.userDetail.mobileNumber || 9399874299,
          'address': this.userDetail.address,
          'city': this.userDetail.city,
          'country': this.userDetail.country,
          'state':this.userDetail.State ,
        })
        this.imgSrc = this.userDetail.profilePic
        this.getState()
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res['message']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.errorToast('Unauthorized Access');
      } else {
        this.service.errorToast('Something Went Wrong');
      }
      this.service.hideSpinner();
    })
  } 


  getState(){
    console.log(this.editProfileForm.value.country);
    this.stateArr = this.country.countries.filter( (el) => {
      return el.country ===  this.editProfileForm.value.country
    });
    console.log(this.editProfileForm.value.state);
  }


  editProfile() {
    let url="admin/editProfile"
    var data = {
      "address": this.editProfileForm.value.address,
      "State":this.editProfileForm.value.state,
      "city": this.editProfileForm.value.city,
      "firstName": this.editProfileForm.value.firstName,
      "lastName": this.editProfileForm.value.lastName,
      "mobileNumber": this.editProfileForm.value.phoneNo,
      "email": this.editProfileForm.value.email,
      "profilePic": this.imgSrc? this.imgSrc : this.userDetail.profilePic
    }
    console.log(data);
      this.service.showSpinner();
      this.service.putApi(url, data,1).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        localStorage.setItem("token", res.result.token);
        this.service.changeLoginSub('login')
        this.service.successToast('Profile Updated Successfully');
        this.service.onFirstComponentButtonClick();
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res.responseMessage);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.errorToast('Unauthorized Access');
      } else {
        this.service.errorToast('Something Went Wrong');
      }
    })
  }
  
// Image Functionality Start Here
uploadImg($event): void {
  var img = $event.target.files[0];
  this.uploadImageFunc(img);
}
uploadImageFunc(img) {
  var fb = new FormData();
  fb.append('file', img)
  this.imgSrc = "assets/loader/1488.gif"
  this.service.showSpinner();
  this.service.uploadFile('static/upload-file', fb).subscribe(res => {
    this.service.hideSpinner();
    if (res['status'] == '200') {
      this.imgSrc = res['data'];
      this.service.hideSpinner();
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      this.service.onLogout();
      this.service.errorToast('Unauthorized Access');
    } else {
      this.service.errorToast('Something Went Wrong');
    }
  })
}
  
  back() {
    // this.router.navigate(['/profile'])
  }
}
