<aside class="sidebar" style="z-index: 7">
    <div class="adminprofile">
        <a class="navbar-brand logo" style="width: 100%; display: inline-flex; justify-content: center;">
            <img class="lg-logo side-img" [src]="profileData?.imageUrl|| 'assets/Product image/user.png'" alt="image" (click)="adminProfile()">
        </a>
        <div id="admin" class="profile-data-section">
            <label style="word-break: break-word;">{{profileData?.firstName  || 'Admin' }} <br *ngIf="[profileData?.firstName].length > 10"> {{profileData?.lastName}}</label>
            <p>{{profileData?.email || 'admin@gmail.com' }}</p>
        </div>
    </div>
    <div class="sidebar-scroller">
        <div id="accordion" class="sidebar-menu">
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/dashboard' ? 'active' : ''" style="border-top: 1px solid #1975a7;">
                <div class="card-header clearfix" [routerLink]="['/dashboard']" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-dashboard" aria-hidden="true"></i></span>
                        <span class="side_page_name head_color">DASHBOARD</span>
                    </a>
                </div>
            </div>
            <div class="menu-box toggle-menu" [ngClass]="{'active':currentUrl == '/list-user' || currentUrl?.includes ('/view-user')}" style="border-top: 1px solid #1975a7;">
                <div class="card-header clearfix" [routerLink]="['/list-user']" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-user" aria-hidden="true"></i></span>
                        <span class="side_page_name head_color">USER MANAGEMENT</span>
                    </a>
                </div>
            </div>
            <div class="menu-box toggle-menu" [ngClass]="{'active':currentUrl == '/list-kyc' || currentUrl?.includes ('/view-kyc')}" style="border-top: 1px solid #1975a7;">
                <div class="card-header clearfix" [routerLink]="['/list-kyc']" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-file-alt" aria-hidden="true"></i></span>
                        <span class="side_page_name head_color">KYC MANAGEMENT</span>
                    </a>
                </div>
            </div> 
            <div class="menu-box toggle-menu" [ngClass]="{'active':currentUrl == '/transaction-management' || currentUrl?.includes ('/view-transaction')}"
            style="border-top: 1px solid #1975a7;">
            <div class="card-header clearfix" [routerLink]="['/transaction-management']" id="headingThree" data-toggle="collapse"
                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <a class="side_menu">
                    <span class="side_menu_icon"><i class="fa fa-exchange" aria-hidden="true"></i></span>
                    <span class="side_page_name head_color"> TRANSACTION MANAGEMENT</span>
                </a>
            </div>
        </div>
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/qr-code' || currentUrl?.includes('discount') ? 'active' : ''"
                style="border-top: 1px solid #1975a7;">
                <div class="card-header clearfix" [routerLink]="['/qr-code']" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-qrcode" aria-hidden="true"></i></span>
                        <span class="side_page_name head_color"> QR CODE</span>
                    </a>
                </div>
            </div> 
            <div class="menu-box">
                <div class="card-header clearfix" id="headingOne" data-toggle="modal" data-target="#logoutModal1">
                    <a class="side_menu" >
                        <span class="side_menu_icon">
                        <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </span>
                        <span class="side_page_name head_color">LOG OUT</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</aside>

<!-- logout modal -->
<div class="modal fade global-modal reset-modal" id="logoutModal1" [ngClass]="showModal ? '' : 'showModal'">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Logout?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to logout?</p>
                                <div class="modal-btn-box">
                                    <button type="button" class="btn btn-theme" (click)="service.onLogout()" data-dismiss="modal">YES</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>