<header  class="header">
    <nav aria-label="" class="navbar clearfix">
        <div class="logo-box" [routerLink]="['dashboard']">
            <a class="navbar-brand logo" id="logo" *ngIf="showLogo == false">
                <img class="image" src="assets/img/admin-logo.png" alt="">
            </a>
            <a class="navbar-brand logo" id="logo1" *ngIf="showLogo == true" >
                <img class="image" src="assets/img/admin-logo.png" alt="">
            </a>
        </div>
        <div class="d-flex align-items-center justify-content-between header-padding">
            <div class="header-right-part" >
                <button class="btn btn-toggle" (click)="head()" type="button" >
                    <em class="fa fa-bars"></em>
                </button>
            </div>
            <button class="btn btn-md  butn" data-toggle="modal" data-target="#logoutModal1">Logout</button>
        </div>
    </nav>
</header>

<!-- logout modal -->
<div class="modal fade global-modal reset-modal" id="logoutModal1" [ngClass]="showModal ? '' : 'showModal'">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Logout?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to logout?</p>
                                <div class="modal-btn-box">
                                    <button type="button" class="btn btn-theme" (click)="service.onLogout()" data-dismiss="modal">YES</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
  
