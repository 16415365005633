<div class="login-wrapper">
  <div class="container-common">
    <div class="row justify-content-center">
      <div class="col-md-6">

         <!--Left container image-->
         <div>
          <img src="/assets/Product image/security.png" alt=" " class="rounded float-left" id="left-container-image">
       </div>
       
        <div class="d-flex align-items-center minheight70vh">
          <form class="login_box_outer w-100" [formGroup]="resetPassword">
            <div class="login-box max-WT-520">
              <div class="login-right-block">
                <div class="login-heading">
                  <div class="login-heading" style="text-align: center;">
                    <img style="font-size: 8px; max-height: 100px;width: 50%;"
                      src="assets/img/admin-logo.png" alt="ICON" class="lg-logo">
                  </div>
                  <h4 style="color: black;">RESET PASSWORD</h4>
                </div>
                <div class="login-box-body">
                  <div class="form-group">
                    <input [type]="newPasswordType" formControlName="password" class="form-control"
                      placeholder="Enter new password" maxlength="56" (keypress)="service.preventSpace($event)" />
                      <div class="input-group-text">
                        <i *ngIf="newPasswordType == 'text'" class="fa fa-eye" aria-hidden="true" (click)="viewNew(0)" style="background-color: transparent;" ></i>
                        <i *ngIf="newPasswordType == 'password'" class="fa fa-eye-slash" aria-hidden="true" (click)="viewNew(1)" style="background-color: transparent;"></i>
                      </div>
                    <div class="text-danger" *ngIf="resetPassword.get('password').hasError('required') && (resetPassword.get('password').dirty || resetPassword.get('password').touched)">
                      *Please enter new password.
                    </div>
                    <div class="text-danger" *ngIf="resetPassword.get('password').hasError('pattern') && !resetPassword.get('password').hasError('required') && (resetPassword.get('password').dirty || resetPassword.get('password').touched)">
                      *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.(example- Mobiloitte@1)
                    </div>
                  </div>
                  <div class="form-group">
                    <input [type]="confirmPassword" formControlName="confirmPassword" class="form-control" placeholder="Enter confirm new password" maxlength="56" (keypress)="service.preventSpace($event)" />
                    <div class="input-group-text">
                      <i *ngIf="confirmPassword == 'text'" class="fa fa-eye" aria-hidden="true" (click)="viewConfirm(0)" style="background-color: transparent;"></i>
                      <i *ngIf="confirmPassword == 'password'" class="fa fa-eye-slash" aria-hidden="true" (click)="viewConfirm(1)" style="background-color: transparent;"></i>
                    </div>
                    <div class="text-danger" *ngIf="(resetPassword.value.password != resetPassword.value.confirmPassword) && resetPassword.get('confirmPassword').dirty ">
                      *New password and confirm new password must be matched.
                     </div>
                  </div>
                </div>
                <div class="text-center mt40">
                  <button [disabled]="resetPassword.invalid || (resetPassword.get('password').value != resetPassword.get('confirmPassword').value)" routerLinkActive="router-link-active" class="btn btn-login btn-large  width100" (click)="resetApi()">SUBMIT</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>