<!-- <main class="middle-content"> -->
    <!-- <div class="page_title_block">
        <h1 class="page_title">TRANSACTION MANAGEMENT </h1>
        <hr>
    </div> -->
    <div class="content-section" id="htmlData">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="sec_head_new sec_head_new_after">
                            <form [formGroup]="searchForm">
                                <div class="row">
                                    <div class="col-md-3 ">
                                        <label class="d-flex align-items-center">From Date:</label>
                                        <input type="date" class="form-control mb20" formControlName="fromDate"  >
                                    </div>
                                    <div class="col-md-3 ">
                                        <label class="d-flex align-items-center">To Date:</label>
                                        <input type="date" formControlName="toDate" [min]="searchForm.value.fromDate" class="form-control mb20">
                                    </div>
                                    <div class="col-md-3">
                                        <select class="form-control form-select" formControlName="text" style="margin-top: 32px;">
                                            <option value="" selected>ALL</option>
                                            <option value="WITHDRAW">WITHDRAW</option>
                                            <option value="DEPOSIT">DEPOSIT</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3">
                                        <div class="text-center admin-btn mt30">
                                            <button type="button" class="btn  btn-theme Go_btn" (click)="search()">Submit</button>
                                            <button type="button" class="btn  btn-theme ml-2 Go_btn" (click)="reset()">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr class="no_wrap_th text-center">
                                    <th class="Sorting-img heading1">S.No.</th>
                                    <th class="Sorting-img">Name</th>
                                    <th class="Sorting-img">Email</th>
                                    <th class="Sorting-img">Transaction Type</th>
                                    <th class="Sorting-img">Transaction Date & Time</th>
                                    <th class="Sorting-img">Transaction Hash</th>
                                    <th class="Sorting-img">Amount</th>
                                    <th class="Sorting-img">Coin Type</th>
                                    <th class="Sorting-img">Transaction ID</th>
                                    <th class="Sorting-img">Transaction Status</th>
                                    <th class="action_td_btn3 Sorting-img heading2" style="width: 15%;">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  *ngFor="let item of transactionlist | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total}; let i = index"  class="text-center">
                                    <td>{{itemPerPage * (currentPage - 1) + i+1}}</td>
                                    <td class="content">{{item?.userName|| '--'}}</td>
                                    <td class="content">{{item?.userEmail|| '--'}}</td>
                                    <td class="content">{{item?. txnType|| '--'}}</td>
                                    <td class="content">{{item?.txnTime| date : 'dd/MM/yyyy, h:mm a'|| '--'}}</td>
                                    <td class="content">{{item?. txnHash|| '--'}}</td>
                                    <td class="content">{{item?.amount|| '--'}}</td>
                                    <td class="content">{{item?.coinType|| '--'}}</td>
                                    <td class="content">{{item?.txnId || '--'}}</td>
                                    <td class="content">{{item?.status || '--'}}</td> 
                                    <td class="justify-content-center d-flex">
                                        <button class="btn btn-info ml-2 bt-margin" title="View" (click)="viewUser(item?.txnId)">
                                            <i class="fa fa-eye" style="color:white" routerLink="/view-transaction"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="[transactionlist].length == 0">
                                    <td class="table-no-record" colspan="13">No Record Found</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > itemPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- </main> -->

