<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">KYC MANAGEMENT </h1>
        <hr>
    </div>
    <div class="content-section" id="htmlData">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="sec_head_new sec_head_new_after">
                            <form [formGroup]="searchForm">
                                <div class="row">
                                    <div class="col-md-3 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <label class="d-flex align-items-center">Search:</label>
                                            <div class="input-group filter_search_group">
                                                <input type="text" class="form-control overflow-text" formControlName="text" placeholder="Search by email" maxlength="60">
                                                <div class="input-group-append">
                                                    <button class="btn btn_search_group"><img src="assets/img/icon-search.png" (click)="search()"  alt="Search"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="col-md-3 ">
                                        <label class="d-flex align-items-center">From Date:</label>
                                        <input type="date" class="form-control mb20" formControlName="fromDate">
                                    </div>
                                    <div class="col-md-3">
                                        <label class="d-flex align-items-center">To Date:</label>
                                        <input type="date" formControlName="toDate" [min]="searchForm.value.fromDate" class="form-control mb20">
                                    </div>
                                   <!-- <div class="col-md-3">
                                        <select class="form-control form-select" formControlName="text" style="margin-top: 32px;">
                                            <option value="" selected>ALL</option>
                                            <option value="WITHDRAW">WITHDRAW</option>
                                            <option value="DEPOSIT">DEPOSIT</option>
                                        </select>
                                    </div>-->
                                    <div class="col-xl-3 col-lg-3 col-md-3">
                                        <div class="text-center admin-btn mt30">
                                            <button type="button" class="btn  btn-theme Go_btn" (click)="search()">Search</button>
                                            <button type="button" class="btn  btn-theme ml-2 Go_btn" (click)="reset()">Reset</button>
                                        </div>
                                    </div> 
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr class="no_wrap_th text-center">
                                    <th class="Sorting-img heading1">S.No.</th>
                                    <th class="Sorting-img">Name</th>
                                    <th class="Sorting-img">Kyc User ID</th>
                                    <th class="Sorting-img">ID Type</th>
                                    <th class="Sorting-img">KYC Date & Time</th>
                                    <th class="Sorting-img">Updated At</th>
                                    <th class="Sorting-img">Status</th>
                                    <th class="Sorting-img">Country</th>
                                    <th class="Sorting-img">ID Number</th>
                                    <th class="Sorting-img">KYC Status</th>
                                    <th class="action_td_btn3 Sorting-img heading2" style="width: 15%;">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  *ngFor="let item of kyclist | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total}; let i = index"  class="text-center">
                                    <td>{{itemPerPage * (currentPage-1) + i+1}}</td>
                                    <td class="content">{{item?.fullLegalName|| '--'}}</td>
                                    <td class="content">{{item?.kycUserId|| '--'}}</td>
                                    <td class="content">{{item?. IdType|| '--'}}</td>
                                    <td class="content">{{item?.createdAt| date : 'dd/MM/yyyy, h:mm a'|| '--'}}</td>
                                    <td class="content">{{item?.updatedAt|| '--'}}</td>
                                    <td class="content">{{item?.status|| '--'}}</td>
                                    <td class="content">{{item?.country|| '--'}}</td>
                                    <td class="content">{{item?.idNumber || '--'}}</td>
                                    <td class="content">{{item?.status || '--'}}</td> 
                                    <td class="justify-content-center d-flex">
                                        <button class="btn btn-info ml-2 bt-margin" title="View" (click)="viewUser(item?._id)">
                                            <i class="fa fa-eye" style="color:white"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="[kyclist].length == 0">
                                    <td class="table-no-record" colspan="13">No Record Found</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > itemPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>