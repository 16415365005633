<div class="order-view  max-WT-700 mrgn-0-auto setting-page">
    <div class="mb40">
        <div class="add-store-block input-style view-label-align" style="width: 90%;">
            <div class="form-group row align-items-baseline">
                <label class="col-md-4 textalignright "> Name<span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.firstName +" "+ userData?.lastName || 'Sushmita'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Email<span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.email|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Contact Number <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.mobileNumber|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Gender <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.gender|| 'Female'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Country <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.country|| 'India'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">State <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.state|| 'Uttar Pradesh'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">City <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.city|| 'Gorakhpur'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Zip Code <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.countryCode|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="text-center mt40 mb40">
                <button type="button" class="btn btn-large  max-WT-150 btn-secondary"
                    [routerLink]="['/list-user']">Back</button>
            </div>
        </div>
    </div>

</div>
