<main class="middle-content" >
    <div class="page_title_block">
        <h1 class="page_title">PROFILE</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="custom_tabs common-tabs">
                        <div class="row mb20 justify-content-center">
                            <div class="col-sm-12">
                                <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                                    <li class="nav-item flex-fill" [ngClass]="this.currentTab=='PERSONAL_INFORMATION'?  'active' : ''">
                                        <a class="nav-link active show " [ngClass]="{'active': currentTab=='PERSONAL_INFORMATION'}" (click)="selectTab('PERSONAL_INFORMATION')" href="javascript:;">PERSONAL INFORMATION</a>
                                    </li>
                                    <li class="nav-item flex-fill">
                                        <a class="nav-link active show " [ngClass]="{'active': currentTab=='SECURITY'}" (click)="selectTab('SECURITY')" href="javascript:;">SECURITY</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <app-profile *ngIf="currentTab == 'PERSONAL_INFORMATION'"></app-profile>
                    <app-change-password *ngIf="currentTab == 'SECURITY'"></app-change-password>
                </div>
            </div>
        </div>
    </div>
</main>