<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">QR CODE MANAGEMENT </h1>
        <hr>
    </div>
    <div class="content-section" id="htmlData">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="sec_head_new sec_head_new_after">
                            <form [formGroup]="searchForm">
                                <div class="row">
                                    <div class="col-md-3 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <label class="d-flex align-items-center">Search:</label>
                                            <div class="input-group filter_search_group">
                                                <input type="text" class="form-control overflow-text" formControlName="text" placeholder="Search by email" maxlength="60">
                                                <div class="input-group-append">
                                                    <button class="btn btn_search_group"><img src="assets/img/icon-search.png" (click)="search()"  alt="Search"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3">
                                        <div class="text-center admin-btn mt30">
                                            <button type="button" class="btn  btn-theme Go_btn" (click)="search()">Search</button>
                                            <button type="button" class="btn  btn-theme ml-2 Go_btn" (click)="reset()">Reset</button>
                                            <button type="button" class="btn btn-theme" (click)="exportAsXLSX()" style="font-size: 12px; padding: 5px;">EXPORT EXCEL</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr class="no_wrap_th text-center">
                                    <th class="Sorting-img heading1"> S.No.</th>
                                    <th class="Sorting-img">Email</th>
                                    <th class="Sorting-img">QR code</th>
                                    <th class="action_td_btn3 Sorting-img heading2" style="width: 15%;">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of QrCodelist | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index"  class="text-center">
                                    <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                    <td class="content">{{item?.email|| '--'}}</td>
                                    <td class="content"><a href=" ">{{item?.qrcode || '--'}}</a></td>
                                    <td class="justify-content-center d-flex">
                                    <button *ngIf="item.qrcodeStatus == 'ACTIVE'" class="btn btn-success ml-2 bth" title="Active" data-toggle="modal" data-target="#BlockModal" (click)="getUserId(item?._id,item?.qrCodeStatus)" >
                                        <em class="fa fa-check" aria-hidden="true"></em>
                                    </button>
                                    <button *ngIf="item.qrcodeStatus == 'BLOCK'" class="btn btn-danger ml-2 bth" title="Blocked" data-target="#BlockModal" data-toggle="modal" (click)="getUserId(item._id,item.qrCodeStatus)">
                                        <em class="fa fa-times" aria-hidden="true"></em>
                                    </button>
                                    </td>
                                </tr>
                                <tr *ngIf="[QrCodelist].length == 0">
                                    <td class="table-no-record" colspan="13">No Record Found</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > itemsPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<!-- block_unblockModal -->
<div class="modal fade global-modal reset-modal" id="BlockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">{{statusString | uppercase}}</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to {{statusString | uppercase}} this QR code?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal"  (click)="blockUnblockUser()" >Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>