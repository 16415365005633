<div>
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">DASHBOARD</h1>
            <hr>
        </div>
        <div class="content-section dashboard-block">
            <ul class="dash_list1 d-flex w-100 flex-wrap ">
                <li class="dashbord-box" style="background-color: rgb(9 49 236 / 88%) ;">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalUser || 0}}</h4>
                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total User</h6>
                    <div>
                        <em class="fa fa-user" style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15);"></em>
                    </div>
                    <div style="margin-top: 48px;">
                        <a routerLink="/list-user" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>
                </li>
                <li class="dashbord-box" style=" background-color: #036ef4;">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalKyc || 0}}</h4>
                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total KYC</h6>
                    <div>
                        <em class="fa fa-file-alt" style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15);"></em>
                    </div>
                    <div style="margin-top: 48px;">
                        <a routerLink="/list-kyc" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>
                </li>
                <!-- margin,width -->
                <li class="dashbord-box transaction" style="background-color:  rgb(9 49 236 / 88%) ;">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalTransaction || 0}}</h4>
                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total Transaction</h6>
                    <div>
                        <em class="fa fa-exchange transaction-icon"></em>
                    </div>
                    <div class="transaction-info">
                        <a routerLink="/transaction-management" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>
                </li>
                <li class="dashbord-box" style="background-color: #036ef4;">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalQR || 0}}</h4>
                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total QR</h6>
                    <div>
                        <em class="fa fa-qrcode" style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15);padding-right: 10px;"></em>
                    </div>
                    <div style="margin-top: 48px;">
                        <a routerLink="/qr-code" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>
                </li>
            </ul>
            <div class="row dash_list1 d-flex w-100 flex-wrap " style="margin-left: unset;">
                <div class=" col-md-6 dashbord-box">
                    <div class="box box-primary">
                        <div class="box-header with-border">
                            <h6 style="font-size: 20px;">Total KYC</h6>
                        </div>
                        <div class="chart"></div>
                        <canvas id="myChart" width="600" height="350"></canvas>
                    </div>
                </div>
                <div class="col-md-6 dashbord-box">
                    <div class="box box-primary1">
                        <div class="box-header with-border">
                            <h6 style="font-size: 20px;">Total Transaction</h6>
                        </div>
                        <div class="chart">
                            <canvas id="myChart2" width="600" height="350"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
