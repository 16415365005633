import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $

@Component({
  selector: 'app-qr-code-management',
  templateUrl: './qr-code-management.component.html',
  styleUrls: ['./qr-code-management.component.scss']
})
export class QRCodeManagementComponent implements OnInit {
  searchForm: FormGroup;
  statusString:any;
  totalItems:any
  itemsPerPage = 5;
  currentPage = 1; 
  limit: any = 5;
  total: any;
  items = [];
  flip : boolean = false
  isSearched : boolean = false
  customerData : any = []
  QrCodelist: any;
  action:any="check";
  title="block";
  userId : any
  status : any

  constructor(private router: Router, private commonService:CommonService,private datepipe: DatePipe) { }

  ngOnInit(): void 
  {
    this.searchFormValidation()
    this.getQrCodeList()
  }

  searchFormValidation()
  {
    this.searchForm = new FormGroup({
    fromDate: new FormControl(''),
    toDate: new FormControl(''),
    text: new FormControl('')
    });
  }

  getQrCodeList(){
    let url = "admin/qrCodeList?page="
    this.commonService.showSpinner()
    this.commonService.postApi(url,{},1).subscribe((res)=>{
      console.log("api req data-->",res)
      if(res.statusCode == 200){
        this.QrCodelist = res.result.docs
        this.totalItems = res.result.docs.total
        this.commonService.successToast(res.responseMessage)
        this.commonService.hideSpinner()
        console.log(this.QrCodelist);
      }else{
            this.commonService.errorToast(res.responseMessage)
            this.commonService.hideSpinner()
           }
    },(err)=>{
     this.commonService.errorToast(err)
     this.commonService.hideSpinner()
    })
  }

  
  pagination(event) {
    this.currentPage = event;
    this.getQrCodeList();
  }

  search(){
    if(this.searchForm.value.text){
      this.getQrCodeList();
    }
  }

  getUserId(id,status){
    this.userId = id
    this.status = status
    if(this.status == 'ACTIVE'){
      this.statusString = "BLOCK"
    }
    else{
      this.statusString = "UNBLOCK"
    }
    console.log(this.status);
    
  }
  blockUnblockUser(){
    var url =""
    if(this.status == 'ACTIVE'){
      url = "admin/block_UnblockQRCode"
    }
    else{
      url = "admin/block_UnblockQRCode"
    }
    const body = {
      "_id": this.userId
    }
    this.commonService.hideSpinner()
    this.commonService.putApi(url,body,1).subscribe((res)=>{
      if (res.statusCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
        this.getQrCodeList()
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    },(err)=>{
      this.commonService.hideSpinner();
      this.commonService.errorToast(err);
    })
  }

  
  reset(){
    if(!this.isSearched){
      return
    }
    this.flip = false
    this.isSearched = false
    this.getQrCodeList()
    this.searchForm.reset()
    this.searchForm.patchValue({
      text : ""
    })
  }

  exportAsXLSX() {
    let dataArr = [];
    this.customerData.forEach((element, ind) => {
      let obj = {}
      obj = {
        "S no": ind + 1,
        "Name" : element.firstName ? element.firstName +" "+ element?.lastName: 'N/A',
        "Email": element.email ? element.email : 'N/A',

        "Phone No": element.phoneNo ? element.phoneNo : 'N/A',
        "Created At": element.createTime ?this.datepipe.transform(element.createTime ,'dd/MM/yyyy, hh:mm a')  : 'N/A',
        "Status": element.userStatus ? element.userStatus  : 'N/A',
      }
      dataArr.push(obj)
    })
    this.commonService.exportAsExcelFile(dataArr, 'User Management');
  }

}
