import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-transaction-details',
  templateUrl: './view-transaction-details.component.html',
  styleUrls: ['./view-transaction-details.component.scss']
})
export class ViewTransactionDetailsComponent implements OnInit {
  txnId : any
  userDetail : any = []
  transactionlist: any;
  total: any;
  constructor(private activated :ActivatedRoute,private service :CommonService) {
    this.activated.queryParams.subscribe((res)=>{
      this.txnId = res.TxnId 
      console.log(this.txnId)
    })
    
   }

  ngOnInit(): void {
    this.getViewTransactionDetail()
}

getViewTransactionDetail(){
  let url = "admin/viewTransaction?_id="+this.txnId
  this.service.showSpinner()
  this.service.getApi(url,1).subscribe((res)=>{
    if(res.statusCode == 200){
      this.transactionlist = res.result
      this.total = res.result.totalCount
     this.service.successToast(res.responseMessage)

     this.service.hideSpinner()
    }else{
     this.service.errorToast(res.responseMessage)
     this.service.hideSpinner()
    }
  },(err)=>{
   this.service.errorToast("Data not found")
   this.service.hideSpinner()
  })
}

}
