import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

declare var $:any;
@Component({
  selector: 'app-list-transaction',
  templateUrl: './list-transaction.component.html',
  styleUrls: ['./list-transaction.component.scss']
})
export class ListTransactionComponent implements OnInit {
  searchForm: FormGroup;
  totalItems:any
  itemsPerPage = 5;
  currentPage = 1; 
  limit: any = 5;
  total: any;
  items = [];
  flip : boolean = false
  isSearched : boolean = false
  customerData : any = [
  ]
  transactionlist: any;

  constructor(private router: Router, private service:CommonService) { }

  ngOnInit(): void {
    this.searchFormValidation()
    this.getTransactionList()
  }
  
  searchFormValidation(){
    this.searchForm = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      text: new FormControl('')
    });
  }
getTransactionList(){
  let apiReqData={
    search:this.searchForm.value.text,
    fromDate:this.searchForm.value.fromDate,
    toDate:this.searchForm.value.toDate
  }
  let url = `admin/transactionList?page=${this.currentPage + "&limit=" + this.itemsPerPage}`;
  this.service.showSpinner()
  this.service.postApi(url,apiReqData,1).subscribe((res)=>{
    if(res.statusCode == 200){
      this.transactionlist = res.result.docs
      this.total = res.result.total
      this.service.successToast(res.responseMessage)
      this.service.hideSpinner()
    }else{
     this.service.errorToast(res.responseMessage)
     this.service.hideSpinner()
    }
  },(err)=>{
   this.service.errorToast("Data not found.")
   this.service.hideSpinner()
  })
}

  pagination(event){
    this.currentPage = event;
      this.search()
      this.getTransactionList()
  }

  viewUser(id) {
    this.router.navigate(['/view-transaction'], { queryParams: { TxnId : id } })
  }

  search(){
    if(!this.searchForm.value.text && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      return
    }
    this.isSearched = true
    let url =""
    const fromDate = Date.parse(this.searchForm.value.fromDate)
    const toDate = Date.parse(this.searchForm.value.toDate)
    if(this.searchForm.value.text && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&txnType="+this.searchForm.value.text
     
    }
    else if(!this.searchForm.value.text && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&fromDate="+fromDate
     
    }
    else if(!this.searchForm.value.text && this.searchForm.value.fromDate && this.searchForm.value.toDate){
      url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&fromDate="+fromDate+"&toDate="+toDate
     
    }
    else if(this.searchForm.value.text && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&fromDate="+fromDate+"&txnType="+this.searchForm.value.text
     
    }
    else{
      url = "account/admin/user-management/filter-user-details?page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&fromDate="+fromDate+"&toDate="+toDate+"&txnType="+this.searchForm.value.text
    
    }
   
    
    
    // this.service.showSpinner();
    this.service.getApi(url, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.transactionlist = res.data.resultlist
          this.total = res.data.totalCount
          this.service.hideSpinner();
          this.service.successToast(res.responseMessage);
        } else {
          this.service.hideSpinner();
          this.service.errorToast(res.responseMessage);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.errorToast("Data not found");
      }
    );
  }
  reset(){
    if(!this.isSearched){
      return
    }
    this.isSearched = false
    this.getTransactionList()
    this.searchForm.reset()
    this.searchForm.patchValue({
      text : ""
    })
  }

}
