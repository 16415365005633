import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $

@Component({
  selector: 'app-list-kyc',
  templateUrl: './list-kyc.component.html',
  styleUrls: ['./list-kyc.component.scss']
})
export class ListKycComponent implements OnInit {
  searchForm: FormGroup;
  totalItems:any
  itemPerPage = 5;
  currentPage = 1; 
  limit: any = 5;
  total: any;
  items = [];
  flip : boolean = false
  isSearched : boolean = false
  customerData : any = []
  kyclist: any;


  constructor(private router: Router, private service:CommonService) { }

  ngOnInit(): void {
    this.searchFormValidation()
    this.getKycList()
  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      text: new FormControl('')
    });
  }

getKycList(){
  let data={
      text:this.searchForm.value.text,
      formData:this.searchForm.value.formData,
      toDate:this.searchForm.value.toDate,
      page:this.currentPage-1,
      limit:this.itemPerPage
  }
  let url = "admin/KycList"
  this.service.showSpinner()
  this.service.postApi(url,data,1).subscribe((res)=>{
    if(res.statusCode == 200){
      this.kyclist = res.result.docs
      this.service.hideSpinner()
      console.log("api req data-->",this.kyclist);
      // this.total = res.data.totalCount
      this.service.successToast(res.responseMessage)
    }else{
     this.service.errorToast(res.responseMessage)
     this.service.hideSpinner()
    }
  },(err)=>{
   this.service.errorToast("Data not found")
   this.service.hideSpinner()
  })
}

  pagination(event){
    this.currentPage = event;
    if(this.flip){
      this.search()
    }
    else{
      this.getKycList()
    }
  }

  viewUser(id) {
    this.router.navigate(['/view-kyc'], { 
      queryParams: 
      { id : id } })
  }

  search(){
    if(!this.searchForm.value.text && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      return}
      this.isSearched = true
      this.flip = true
      let url =""
      const fromDate = Date.parse(this.searchForm.value.fromDate)
      const toDate = Date.parse(this.searchForm.value.toDate)
      if(this.searchForm.value.text && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
        url = "admin/KycList?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&txnType="+this.searchForm.value.text

      }
      else if(!this.searchForm.value.text && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
          url = "admin/KycList?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate
        }
      else if(!this.searchForm.value.text && this.searchForm.value.fromDate && this.searchForm.value.toDate){
        url = "admin/KycList?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate+"&toDate="+toDate
      }
      else if(this.searchForm.value.text && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
        url = "admin/KycList?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate+"&txnType="+this.searchForm.value.text
      }
      else{
        url = "account/admin/user-management/filter-user-details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate+"&toDate="+toDate+"&txnType="+this.searchForm.value.text
      }
    // this.service.showSpinner();
    this.service.getApi(url, 1).subscribe(
      (res) => {
        if (res.responseCode == 200) {
          this.kyclist = res.data.resultlist
          // this.total = res.data.totalCount
          this.service.hideSpinner();
          this.service.successToast(res.message);
        } else {
          this.service.hideSpinner();
          this.service.errorToast("Error");
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.errorToast("Data not found");
      }
    );
  }

  
  reset(){
    if(!this.isSearched){
      return
    }
    this.flip = false
    this.isSearched = false
    this.getKycList()
    this.searchForm.reset()
    this.searchForm.patchValue({
      text : ""
    })
  }

}
