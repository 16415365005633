import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

declare var $:any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  otp : any
  form: FormGroup;
  email: any;
  showModal : boolean = false;
  countryCode:any;
  mobileNumber:any;

  constructor(private router: Router, public service: CommonService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,7}|[0-9]{1,3})(\]?)$/i)]))
    })
  }

  // very otp on click verify
  veryfyOtp(){
    let url="admin/verifyOTP"
    let apiReqData={
      "countryCode":this.form.value.countryCode,
      "mobileNumber":this.form.value.mobileNumber,
      "otp":this.form.value.otp
    }
    this.service.showSpinner()
    this.service.postApi(url,apiReqData,0).subscribe((res)=>{
      if(res.responseCode==200)
      {
        this.service.successToast(res.responseMessage)
        this.showModal=false
        this.router.navigate(['/reset-password'])
        this.service.hideSpinner()
      }
      else{
        this.service.errorToast("Invalid Otp")
        this.service.hideSpinner()
      }
    },
    (err)=>{
      this.service.errorToast(err.responseMessage)
      this.service.hideSpinner()
    })
    }

    // verify email on click 
    verfyEmail()
    {
    let apiReqData={
      "email": this.form.value.email,
    }
    console.log("forgot api req data->",apiReqData);
    this.service.showSpinner()
    this.service.postApi("admin/forgetPassword",apiReqData,0).subscribe((res:any)=>{
      if(res.statusCode==200)
      {
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
        this.showModal=true
        localStorage.setItem('email',btoa(this.form.value.email))
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    },
    (err:any)=>{
      this.service.hideSpinner();
      this.service.errorToast("Email not matched.")
    }
    )
  }
  
  onOtpChange(e) {
    this.otp = e;
    console.log(this.otp)
  }
  
  // to hide modal
  hideModal(){
   this.showModal = false
  }

}
